import * as React from "react";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";

const PageTemplate = ({ data }) => {
  const page = data.page;

  return (
    <Layout>
      <Seo title={page.title} bodyClass="page" />
      <section className="section" aria-labelledby="page-title">
        <div className="container">
          <article>
            <header>
              <h1 id="page-title">{page.title}</h1>
            </header>
            {page.content && renderRichText(page.content)}
          </article>
        </div>
      </section>
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query ($slug: String) {
    page: contentfulPage(slug: { eq: $slug }) {
      title
      content {
        raw
      }
    }
  }
`;
